import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { applyBoxUV } from './helpers/ApplyBoxUV';
import { makeMaskRobloxMaterial } from './helpers/makeMaskRobloxMaterial';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { type } from 'node:os';
import { WebGLProgramParametersWithUniforms } from 'three';

export enum TEXTURE_USAGE {
  ALBEDO,
  NORMAL,
  ORM,
}

export type ImageLoadedType = {
  img: HTMLImageElement;
  time: number;
}

export class RenderApp {
  protected _clock = new THREE.Clock();
  protected _currentScene = new THREE.Scene();
  protected _meshAssets: Record<string, THREE.Object3D> = {};
  protected _renderer = new THREE.WebGLRenderer( { antialias: true } );
  protected _environment = new RoomEnvironment();
  protected _pmremGenerator = new THREE.PMREMGenerator( this._renderer );
  protected _camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 100 );
  protected _controls = new OrbitControls(this._camera, this._renderer.domElement);

  protected _textureLoadingSpeed?: number;
  protected _loadedImages: Record<string, ImageLoadedType> = {};

  public init() {
    this._renderer.setPixelRatio( window.devicePixelRatio );
    this._renderer.setSize( window.innerWidth, window.innerHeight );
    this._renderer.shadowMap.enabled = true;
    this._currentScene.background = new THREE.Color( 0xa0a0a0 );
    this._currentScene.fog = new THREE.Fog( 0xa0a0a0, 10, 50 );
    this._currentScene.environment = this._pmremGenerator.fromScene( this._environment ).texture;
    this.initLight();
    this.addGround();
    this.addModel();
    this.initCamera();
    this.initControls();
    this.setupXRLiveReload();
    window.addEventListener( 'resize', this._onWindowResize.bind(this) );
    this.buildShader();
    this.runExp();
  }

  protected getTexturesInfo() {
    // in real application we need backend for with information
    // and right now we support only one object
    return [
      { path: '/models/textures/sna_291121_agmg_japandress_albedo.webp', size: 168802, usage: TEXTURE_USAGE.ALBEDO },
      { path: '/models/textures/sna_291121_agmg_japandress_normal.webp', size: 281404, usage: TEXTURE_USAGE.NORMAL },
      { path: '/models/textures/sna_291121_agmg_japandress_orm.webp', size: 568460 , usage: TEXTURE_USAGE.ORM }
    ]
  }

  protected async calculateTextureLoadingSpeed() {
    // in real app we will need some test file for this operation, but here we will use just smallest image form textures
    const smallestTexture = this.getTexturesInfo()
      .sort((a, b) => a.size - b.size)[0];
    if (smallestTexture) {
      const imageInfo = await this.loadImage(smallestTexture.path);
      this._textureLoadingSpeed = smallestTexture.size / imageInfo.time;
    }
  }

  protected getBiggerTexture() {
    return this.getTexturesInfo().sort((a, b) => b.size - a.size)[0];
  }

  protected _loadAllTextures() {
    return Promise.all(this.getTexturesInfo().map((ti) => {
      return this.loadImage(ti.path);
    }));
  }

  protected get _isAllTexturesLoaded() {
    let loaded = true;
    this.getTexturesInfo().forEach((ti) => {
      if (!this._loadedImages[ti.path]) loaded = false;
    });
    return loaded;
  }

  protected loadImage(path: string) {
    // TODO: rewrite to createImageBitmap + fetch
    if (this._loadedImages[path]) return Promise.resolve(this._loadedImages[path]);
    const img = new Image();
    return new Promise<ImageLoadedType>((resolve, reject) => {
      const startTime = Date.now();
      img.addEventListener('load', () => {
        const info = { img, time: Date.now() - startTime };
        this._loadedImages[path] = info;
        resolve(info);
      });
      img.addEventListener('error', (e) => {
        reject(e);
      });
      img.src = path;
      // document.body.appendChild(img);
    });
  }

  protected _applyTextures(obj: THREE.Mesh) {
    const mat = obj.material as THREE.MeshPhysicalMaterial;
    this.getTexturesInfo().forEach((ti) => {
      const { img } = this._loadedImages[ti.path];
      if (!img) return;
      const texture = new THREE.Texture(img);
      texture.needsUpdate = true;
      texture.format = THREE.RGBAFormat;
      texture.colorSpace = THREE.SRGBColorSpace;
      texture.flipY = false;
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      if (ti.usage === TEXTURE_USAGE.ALBEDO) {
        mat.map = texture;
      }
      if (ti.usage === TEXTURE_USAGE.NORMAL) {
        mat.normalMap = texture;
      }
      if (ti.usage === TEXTURE_USAGE.ORM) {
        mat.roughnessMap = texture;
        mat.aoMap = texture;
        mat.metalnessMap = texture;
      }
    });
    mat.needsUpdate = true;
  }

  protected getShaderConfig() {
    return [
      {
        // object name
        name: 'sna_291121_agmg_japandress_mesh',
        // grid size (depends on UV) -- step for number of points,
        gridStep: '0.005',
        // size of point, 1 -- full surface, 2 -- 1/2 space in grid to point, 3 - 1/3 space in grid for point ...
        gridDensity: '5.0',
        // speed for first step -- point
        speedOne: 30/1000,
        // speed for second step -- surface
        speedTwo: 30/1000,
        // setInterval second argument
        intervalTime: 13,
      }
      ]
  }

  protected computeUV (obj: THREE.Mesh) {
    // if no UV we need to generate it
    if (!obj.geometry.attributes.uv) {
      obj.geometry.computeBoundingBox();
      let bboxSize = obj.geometry.boundingBox!.getSize(new THREE.Vector3());
      let uvMapSize = Math.min(bboxSize.x, bboxSize.y, bboxSize.z);
      applyBoxUV(obj.geometry, new THREE.Matrix4().identity(), uvMapSize);
      (obj.geometry.attributes.uv as THREE.BufferAttribute).needsUpdate = true;
    }
  }

  protected buildShader() {
    this.getShaderConfig().forEach(({
        name, gridStep, gridDensity , speedOne, speedTwo, intervalTime
      } ) => {
      const obj = this._currentScene.getObjectByName(name) as THREE.Mesh;
      this.computeUV(obj);
      let compileVersion = 1;
      // In the current code we assume that we always have MeshStandardMaterial material !!!!!
      const mat = obj.material as THREE.MeshStandardMaterial;
      // If we don't have texture, we need to generate it, because UV does not exist in shader without it
      if (!mat.map) {
        const texture = new THREE.DataTexture( new Uint8Array([255, 255, 255, 255]), 1, 1 );
        texture.needsUpdate = true;
        mat.map = texture;
      }
      // To be sure that shader will be recompiled
      mat.customProgramCacheKey = () => {
        return `${compileVersion}`;
      }
      // We use onBeforeCompile, just to do it in the simplest way
      // In real application we can define own ShaderPart in ShaderLib dict
      // For more details you can read this article https://medium.com/@pailhead011/extending-three-js-materials-with-glsl-78ea7bbb9270
      mat.onBeforeCompile = (shader) => {
        // if (compileVersion > 1) return;
        const bbox = new THREE.Box3().setFromObject(obj);
        // We use bbox to define direction for shader
        shader.uniforms.bbox = { value: new THREE.Vector2(bbox.min.z, bbox.max.z) };
        // We start timers from 1 and got to 0
        shader.uniforms.fTime = {value: 1.0};
        shader.uniforms.sTime = {value: 1.0};
        // in vertex shader we just define variable for position and bbox
        // #include <common> && #include <fog_vertex> -- not really good solution
        shader.vertexShader = shader.vertexShader.replace('#include <common>',
          `#include <common>
            uniform vec2 bbox;
            varying vec3 v_WPosition;`
        );
        shader.vertexShader = shader.vertexShader.replace('#include <fog_vertex>',
          `#include <fog_vertex>
            v_WPosition = worldPosition.xyz;
            `
        );
        // console.log(shader.vertexShader);

        // all realization in fragment shader
        shader.fragmentShader = shader.fragmentShader.replace('#include <common>',
          `#include <common>
            varying vec3 v_WPosition;
            uniform vec2 bbox;
            uniform float fTime;
            uniform float sTime;
`
        );
        shader.fragmentShader = shader.fragmentShader.replace('vec4 diffuseColor = vec4( diffuse, opacity );',
          `vec4 diffuseColor = vec4( diffuse, opacity );
            float z = (v_WPosition.z - bbox.x)/ (bbox.y - bbox.x);
            float gridDensity = ${gridDensity};
            if (sTime < 1.0) {
              if (z > sTime) gridDensity = 1.0;
            }
            if (z < fTime) discard;
            vec2 uv = vMapUv;
            // vec3 uv = vUv;
            float gridStep = ${gridStep};
            vec2 gridPos = vec2(uv.x / gridStep, uv.y / gridStep);
            vec2 gridTarget = round(gridPos) * gridStep;
            if (distance(gridTarget, uv) > gridStep / gridDensity) discard;
          `
        );
        mat.userData['shader'] = shader;
        compileVersion += 1;
        console.log('compileVersion', compileVersion);
      };
      // mat.needsUpdate = true;

    });
  }

  protected runExp() {
    makeMaskRobloxMaterial(this._currentScene.getObjectByName('ghost') as THREE.Mesh);
    this.getShaderConfig().forEach(({
        name, gridStep, gridDensity , speedOne, speedTwo, intervalTime
      } ) => {
      const obj = this._currentScene.getObjectByName(name) as THREE.Mesh;
      // In the current code we assume that we always have MeshStandardMaterial material !!!!!
      const mat = obj.material as THREE.MeshStandardMaterial;
      // Here we just use setTimeout
      setTimeout(() => {
          console.log(obj);
          let fTime = 1;
          let sTime = 1;

          // some very slow speed to show something on start
          let startSpeed = 0.1 / 1000;
          let isCalculated = false;

          this.calculateTextureLoadingSpeed();
          // Here we use setInterval, but in real application it's need to be rewritten on requestAnimation frame with controlling delta time manually
          const fTimeHandler = setInterval(() => {

            // we know load speed
            if (typeof this._textureLoadingSpeed !== 'undefined' && !isCalculated) {
              // 1.2 just for ... some fucking magic
              const time = this.getBiggerTexture().size / this._textureLoadingSpeed * 1.2;
              const iterations = time / intervalTime;
              // here speedOne parameter is like minimum limit, so if speed to hi you can see effect
              startSpeed = Math.min(fTime / iterations, speedOne);
              isCalculated = true;
              // not sure that this is a right place to start loading all other textures
              this._loadAllTextures();
            }

            fTime -= startSpeed;
            const shader  = mat.userData['shader'] as WebGLProgramParametersWithUniforms;
            if (!shader) return;
            shader.uniforms.fTime = { value: fTime };
            if (fTime <= 0) {
              clearInterval(fTimeHandler);
              // here we need to set up new textures
              // if magic happened, and we calculate all right and textures loaded ...
              if (this._isAllTexturesLoaded) this._applyTextures(obj);
              const sTimeHandler = setInterval(() => {
                sTime -= speedTwo;
                const shader  = mat.userData['shader'] as WebGLProgramParametersWithUniforms;
                if (!shader) return;
                shader.uniforms.sTime = { value: sTime };
                shader.uniforms.fTime = { value: 0 };
                if (sTime <= 0) clearInterval(sTimeHandler);
              }, intervalTime);
            }
          }, intervalTime);

      }, 200);
    });
  }

  protected initControls() {
    this._controls.enablePan = true;
    // this._controls.enableZoom = false;
    this._controls.target.set( 0, 1, 0 );
    this._controls.update();
  }

  protected initCamera() {
    this._camera.position.set( - 1, 2, 2 );
  }

  protected addModel() {
    console.log(this._meshAssets)
    this._currentScene.add(this._meshAssets['test']);
  }

  public async loadAssets() {
    const loader = new GLTFLoader();
    loader.setMeshoptDecoder(MeshoptDecoder);
    return new Promise<void>((resolve, reject) => {
      loader.load( 'models/LOD1.glb', ( gltf ) => {
        this._meshAssets['test'] = gltf.scene;
        gltf.scene.traverse( function ( object ) {
          if ('isMesh' in object && object.isMesh) object.castShadow = true;
        });
        const bbox = new THREE.Box3().setFromObject(gltf.scene);
        gltf.scene.position.y = - bbox.min.y;
        console.log();
        resolve();
      });
    });
  }

  protected initLight() {
    const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x8d8d8d, 1 );
    hemiLight.position.set( 0, 20, 0 );
    this._currentScene.add( hemiLight );

    const dirLight = new THREE.DirectionalLight( 0xffffff, 3 );
    dirLight.position.set( 3, 10, 10 );
    dirLight.castShadow = true;
    const size = 5;
    dirLight.shadow.camera.top = size;
    dirLight.shadow.camera.bottom = -size;
    dirLight.shadow.camera.left = -size;
    dirLight.shadow.camera.right = size;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 140;
    this._currentScene.add( dirLight );
  }

  protected addGround() {
    const mesh = new THREE.Mesh( new THREE.PlaneGeometry( 100, 100 ), new THREE.MeshPhongMaterial( { color: 0xcbcbcb, depthWrite: false } ) );
    mesh.rotation.x = - Math.PI / 2;
    mesh.receiveShadow = true;
    this._currentScene.add( mesh );
  }

  public destroy() {
    // this.
  }

  public appendTo(el: Element) {
    el.appendChild(this._renderer.domElement);
  }

  public runLoop() {
    const animate = () => {
      requestAnimationFrame( animate );
      this._render();
    }
    requestAnimationFrame( animate );
  }

  protected _onWindowResize() {
    this._camera.aspect = window.innerWidth / window.innerHeight;
    this._camera.updateProjectionMatrix();
    this._renderer.setSize( window.innerWidth, window.innerHeight );
  }

  protected _render() {
    this._controls.update();
    this._renderer.render( this._currentScene, this._camera );
  }

  protected setupXRLiveReload(): void {
    // @ts-ignore
    const hot = window?.module?.hot || import.meta.webpackHot;
    if (hot?.addStatusHandler) {
      // @ts-ignore
      hot.addStatusHandler((e) => {
        if (e === 'check') {
          if (this._renderer) {
            this._renderer.xr.getSession()?.end();
          }
          window.location.reload();
        }
      });
    }
  }
}

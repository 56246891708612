import React, { useEffect, useRef } from 'react';
import './App.css';
import { RenderApp } from './app/RenderApp';

function App() {
  const containerRef = useRef(null);

  const app = useRef(new RenderApp());

  useEffect( () => {
    if (!containerRef.current) return;
    app.current.loadAssets().then(() => {
      if (!containerRef.current) return;
      app.current.init();
      app.current.appendTo(containerRef.current);
      app.current.runLoop();
    });
    return () => {
      app.current.destroy();
    }
  }, []);

  return (
    <div className="App">
      <div ref={containerRef} ></div>
    </div>
  );
}

export default App;

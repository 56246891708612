import * as Three from 'three';
import { Mesh } from 'three';

export function makeMaskRobloxMaterial(mesh: Mesh): void {
  const vertexShader = `
        varying vec3 vNormal;
        varying vec3 vViewPosition;

        void main() {
            vNormal = normalize(normalMatrix * normal);
            vViewPosition = - (modelViewMatrix * vec4(position, 1.0)).xyz;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `;

  const fragmentShader = `
        uniform vec3 lightColor;
        uniform vec3 darkColor;
        uniform float fresnelFactor;
        uniform float exposure;
        varying vec3 vNormal;
        varying vec3 vViewPosition;

        void main() {
            float fresnelTerm = dot(normalize(vViewPosition), vNormal);
            fresnelTerm = pow(clamp(1.0 - fresnelTerm, 0.0, 1.0), fresnelFactor);
            vec3 color = mix(darkColor, lightColor, fresnelTerm);
            color *= exposure; // Apply exposure
            gl_FragColor = vec4(color, 1.0);
        }
    `;
  const material = new Three.ShaderMaterial({
    vertexShader,
    fragmentShader,
    uniforms: {
      lightColor: { value: new Three.Color(0xffffff) },
      darkColor: { value: new Three.Color(0xDADADA) },
      fresnelFactor: { value: 1.2 },
      exposure: { value: 1.0 },
    },
  });

  mesh.material = material;
  mesh.material.needsUpdate = true;
}